<template>
  <div class="events-detail content">
    <div class="entrants--actions-header pb-2 d-flex justify-content-end">
      <!--      <ClSearch-->
      <!--        placeholder="tableHeader.search"-->
      <!--        @searchUpdate="searchData"-->
      <!--      />-->
      <div class="d-flex align-items-center">
        <!-- FILTER ICON -->
        <!--        <ClFilter-->
        <!--          class="mr-3"-->
        <!--          :isFlag="false"-->
        <!--          @toggleFilter="showColumnFilter"-->
        <!--        />-->
        <CButton
          size="sm"
          class="header-icon-buttons mr-3"
          @click.stop.prevent="handleReload"
        >
          <CIcon name="cil-reload"/>
        </CButton>
        <ClResponsiveButton
          :label="$t('buttons.create')"
          @handler="createNewRecord"
        />
      </div>
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody v-if="ready">
            <CDataTable
              id="dataTableId"
              ref="dataTableRef"
              class="zq--table"
              :class="{  }"
              :items="walletTypesData"
              :fields="fields"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              :sorter-value.sync="sorterValue"
            >
              <template #id="{ item }">
                <td class="nth-id">
                  <div class="copy-ceil d-flex align-items-center">
                    <div class="link" @click="showPreview(item)">
                      {{ item.id }}
                    </div>
                    <i
                      class="fa fa-copy zq-card-row--copy-icon"
                      @click="handleCopy(item.id)"
                    />
                  </div>
                </td>
              </template>
              <template #loading>
                <TableLoader/>
              </template>
              <template #select-filter>
                <div></div>
              </template>
              <template v-for="item in columnFilterFields" #[item]>
                <ColumnFilter
                  :key="item"
                  :field="item.slice(0, -7)"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #select="{ item }">
                <td
                  :data-id="item.id"
                  :class="{ 'column-shadow-start': isTableScrollbar }"
                  class="text-center nth-select select-td fixed-column-start"
                >
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedRecords.indexOf(item.id) > -1"
                    @handler="checkRecords"
                  />
                </td>
              </template>
              <template #actions-filter>
                <div></div>
              </template>
              <!--        ACTIONS        -->
              <template #actions="{ item }">
                <ClTableActionsDropdown
                  @choice="dropDownChoice"
                  :itemProp="item"
                  :actionsProp="dropDownActions"
                  :isTableScrollbarProp="isTableScrollbar"
                  :key="item.id"
                />
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              :pages="pages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              @updatePagenation="paginationChange"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- DELETE  MODAL   -->
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="deleteTitle"
      @doFunction="deleteSelectedField"
      v-on:toggle-modal="closeModal('delete')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClSearch from '@/shared/components/formComponents/ClSearch';
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import { pageConfig } from '@/config';
import ClFilter from '@/components/header/ClFilter';
import ColumnFilter from '@/components/table/ColumnFilter';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox.vue';
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown.vue';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton.vue';
import Modal from '@/shared/UI/Modal.vue';
import { cloneDeep, delay } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';

export default {
  name: 'WalletTypesTable',
  components: {
    Modal,
    ClResponsiveButton,
    ClTableActionsDropdown,
    ClCheckbox,
    ColumnFilter,
    ClFilter,
    ClSearch,
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      walletTypesData: [],
      ready: false,
      columnFilter: false,
      isTableScrollbar: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: [
        {
          queryField: 'timestamp',
          order: 'Desc'
        }
      ],
      page: pageConfig.page,
      query: null,
      multiFields: [],
      entriesOptions: [10, 20, 40, 60, 80, 100],
      loading: false,
      selectedRecords: [],
      columnFilterFields: [],
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editRecord',
          disabled: false,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: false,
        },
      ],
      selectedId: null,
      deleteModal: false,
      toggleFlag: false,
      deleteTitle: 'Delete Wallet Type',
      messageGeneral: `You are about to delete wallet type , Are you sure?`
    };
  },
  props: {
    entityId: String,
    entityType: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('walletTypes', ['pages', 'totalRecords', 'fields', 'sortableFields', 'searchableAllFields']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      }
    },
  },
  created() {
    this.fetchWalletTypes();
  },
  methods: {
    ...mapActions('walletTypes', ['handleGetWalletTypes', 'handleDeleteWalletTypes']),
    async fetchWalletTypes() {
      try {
        const payload = { idArray: [], limit: this.itemsPerPage, skip: (this.page - 1) * this.itemsPerPage };
        this.walletTypesData = await this.handleGetWalletTypes(payload);
        routerBreadcrumbs(this.$router.currentRoute);

        this.ready = true;
      } catch (error) {
        console.error('Error fetching wallet types:', error);
      }
    },
    async itemsPerPageSelect(val) {
      if (val) {
        this.itemsPerPage = val;
        this.page = 1;
        await this.fetchWalletTypes();
      }

    },
    async paginationChange(val) {
      this.page = val;
      await this.fetchWalletTypes();
    },
    async searchData(value) {
      this.query = value.trim() ? [{ queryFields: this.searchableAllFields, queryValue: value }] : null;
      await this.fetchWalletTypes();
    },
    async handleReload() {
      await this.fetchWalletTypes();
    },
    async handleSortBy(e) {
      this.sortBy = [{ queryField: e.column, order: e.asc ? 'Asc' : 'Desc' }];
      this.page = 1;
      await this.fetchWalletTypes();
    },
    async showColumnFilter() {
      this.columnFilter = !this.columnFilter;
      if (!this.columnFilter) await this.fetchWalletTypes();
    },
    async handleColumnFilter(e) {
      this.multiFields = e.field && e.value ? [{ queryFields: [e.field], queryValue: e.value }] : [];
      await this.fetchWalletTypes();
    },
    checkRecords({ event }) {
      const index = this.selectedRecords.indexOf(event.target.value);
      if (index > -1) {
        this.selectedRecords.splice(index, 1);
      } else {
        this.selectedRecords.push(event.target.value);
      }
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item);
      }
    },
    createNewRecord() {
      this.$router.push({
        name: 'CreateWalletType',
      });
    },
    showPreview(item) {
      this.$router.push({
        name: 'PreviewWalletType',
        params: {
          id: item.id,
        },
      });
    },
    editRecord(item) {
      this.$router.push({
        name: 'EditWalletType',
        params: {
          id: item.id,
        },
      });
    },
    handleDeleteModal(item) {
      this.deleteModal = true;
      this.selectedId = [item.id];
    },
    async deleteSelectedField() {
      this.deleteModal = false;
      await this.handleDeleteWalletTypes({idArray: [this.selectedId]});
      this.selectedRecords = [];
      this.toggleFlag = false;
      delay(async () => {
        await this.fetchWalletTypes();
      }, 1000);
    },
    closeModal() {
      this.deleteModal = false
    },
    handleCopy(id) {
      navigator.clipboard.writeText(id);
    },
  }
};
</script>

<style scoped lang="scss">
.entrants {
  &--actions-header {
    display: flex;
    justify-content: space-between;
  }
}

.zq-card-row--copy-icon {
  margin-left: 0.5rem;
  cursor: copy;
}
</style>
